import {
  filter,
  forEach,
  identity,
  isEmpty,
  isEqual,
  isFinite,
  isFunction,
  isNil,
  join,
  split,
} from 'lodash';

export const parseQueryStateValue = (type, value, defaultValue = null) => {
  switch (type) {
  case String:
    return isNil(value) ? '' : value;
  case Number:
    return isFinite(+value) ? +value : defaultValue;
  case Array:
    return filter(split(value || '', ','), identity);
  default:
    return JSON.stringify(value);
  }
};

export const parseQueryState = (model, params) => {
  const state = {};

  forEach(model, (field, id) => {
    const defaultValue = isFunction(field.default) ? field.default() : field.default;
    const value = parseQueryStateValue(field.type, params[id]);

    if (!isFunction(field.validator)) {
      state[id] = isNil(value) ? defaultValue : value;
      return;
    }

    state[id] = field.validator(value) ? value : defaultValue;
  });

  return state;
};

export const composeQueryStateValue = (type, value) => {
  switch (type) {
  case String:
    return isNil(value) ? '' : value;
  case Number:
    return `${value}`;
  case Array:
    return join(value || [], ',');
  default:
    return JSON.parse(value);
  }
};

export const composeQueryState = (model, state) => {
  const params = {};

  forEach(model, (field, id) => {
    params[id] = composeQueryStateValue(field.type, state[id]);
  });

  return params;
};

export const normalizeQueryParameters = (model, query) => composeQueryState(model, parseQueryState(model, query));

export const optimizeQueryParameters = (model, query) => {
  const optimizedQuery = {};

  forEach(model, (field, id) => {
    if (isNil(query[id])) return;

    const defaultValue = isFunction(field.default) ? field.default() : field.default;
    const parsedValue = parseQueryStateValue(field.type, query[id], defaultValue);

    if (
      isEqual(defaultValue, parsedValue)
      || (isFunction(field.validator) && !field.validator(parsedValue))
    ) return;

    const queryValue = composeQueryStateValue(field.type, parsedValue);

    if (isEmpty(queryValue)) return;

    optimizedQuery[id] = queryValue;
  });

  return optimizedQuery;
};
