import { debounce } from 'lodash';
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { fetchSportById, fetchRegionById, fetchCompetitionById } from '@/services/api';

export default ({
  initialLabel = '',
  fallbackLabel = 'N/A',
  disableCompetitions = false,
} = {}) => {
  const abortController = ref(null);
  const label = ref(initialLabel ?? '');
  const covered = ref(false);
  const loading = ref(false);

  const store = useStore();
  const sportId = computed(() => store.getters.sidebarSportId);
  const regionId = computed(() => store.getters.sidebarRegionId);
  const competitionId = computed(() => store.getters.sidebarCompetitionId);

  const syncSelection = debounce(async () => {
    try {
      if (abortController.value) abortController.value.abort();
      abortController.value = new AbortController();

      loading.value = true;
      covered.value = false;

      if (!disableCompetitions && competitionId.value) {
        const competition = await fetchCompetitionById(competitionId.value, abortController.value);
        label.value = competition.competitionName;
        covered.value = !!competition.coverageAvailability.nodes?.[0]?.available;
        return;
      }

      if (regionId.value) {
        const region = await fetchRegionById(regionId.value, abortController.value);
        label.value = region.regionName;
        return;
      }

      if (sportId.value) {
        const sport = await fetchSportById(sportId.value, abortController.value);
        label.value = sport.sportName;
        return;
      }

      label.value = initialLabel;
    } catch (error) {
      console.error(error);
      label.value = fallbackLabel;
    } finally {
      loading.value = false;
      abortController.value = null;
    }
  }, 10);

  watch(
    [
      sportId,
      regionId,
      competitionId,
    ],
    syncSelection,
    { immediate: true },
  );

  return {
    loading,
    label,
    covered,
  };
};
