import { entries, isEmpty, isFunction } from 'lodash';
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { normalizeQueryParameters } from '@/services/helpers/query-parameters';

const callOrWarn = (callback, message, ...args) => {
  if (!isFunction(callback)) {
    console.warn(message);
    return;
  }
  callback(...args);
};

export default (queryModel, callbacks) => {
  const route = useRoute();

  const hasInitialized = ref(false);
  const parameters = computed(() => normalizeQueryParameters(queryModel, route.query));

  watch(
    parameters,
    (newParameters, oldParameters) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const [id, field] of entries(queryModel)) {
        if (field.required && isEmpty(parameters.value[id])) {
          callOrWarn(
            callbacks?.onDeclare,
            'onDeclare not handled in watchQueryParameters function',
            id,
          );
          return;
        }
      }

      if (!hasInitialized.value) {
        callOrWarn(
          callbacks?.onInit,
          'onInitialize not handled in watchQueryParameters function',
          newParameters,
        );
        hasInitialized.value = true;
        return;
      }

      callOrWarn(
        callbacks?.onChange,
        'onChange not handled in watchQueryParameters function',
        newParameters,
        oldParameters,
      );
    },
    { immediate: true },
  );
};
