import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  composeQueryState,
  parseQueryState,
  normalizeQueryParameters,
  optimizeQueryParameters,
} from '@/services/helpers/query-parameters';

export default (queryModel) => {
  const route = useRoute();
  const router = useRouter();

  const state = computed(() => parseQueryState(queryModel, route.query));
  const parameters = computed(() => composeQueryState(queryModel, state.value));

  const setParameters = (newQueryParameters) => {
    const normalizedQueryParameters = normalizeQueryParameters(queryModel, newQueryParameters);
    const optimizedQueryParameters = optimizeQueryParameters(queryModel, normalizedQueryParameters);
    router.replace({ ...route, query: optimizedQueryParameters });
  };

  return {
    state,
    parameters,
    setParameters,
  };
};
